




















import { Component, Prop, Vue } from "vue-property-decorator";
import { Notification } from "@/utils/notification";

@Component({})
export default class AppAlert extends Vue {
  @Prop({ type: Object, required: false })
  readonly notification!: Notification;

  @Prop({ type: String, required: false })
  readonly kind!: "error" | "success" | "warning" | "info";

  @Prop({ type: String, required: false })
  readonly icon!: string;

  @Prop({ type: Boolean, default: false })
  readonly excludeHeadline!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly excludeMessage!: boolean;

  alertType(): string | null {
    if (this.kind) return `alert-${this.kind}`;
    else if (this.notification) return `alert-${this.notification.type}`;
    return null;
  }

  defaultHeadline(): string | null {
    const notification = this.notification;
    if (notification && notification.headline) return notification.headline;
    return null;
  }

  defaultMessage(): string | null {
    const notification = this.notification;
    if (notification && notification.message) return notification.message;
    return null;
  }
}
